@import url('https://fonts.googleapis.com/css?family=Poppins&display=swap');
@import './variables.scss';
@import 'bootstrap/scss/bootstrap.scss';
@import './buttons.scss';

html {
    height: 100%;
    width: 100%;
}

body {
    background-repeat: no-repeat;
    overflow-x: hidden;
    font-family: 'Poppins', sans-serif;
    min-height: 100%;
    width: 100%;
}



.pointer {
    cursor: pointer;
}

.no-pointer {
    cursor: initial !important;
}

.text-eco {
    color: #666666;
}

.no-text-decoration {
    text-decoration: none !important;
}

.zoom {
    transition: transform 0.2s ease-in-out;
}

.zoom:hover {
    transform: scale(1.2);
}

.btn-outline-primary:hover {
    color: white !important;
}

.no-outline {
    outline: none;
}

.no-select {
    -webkit-touch-callout: none;
    /* iOS Safari */
    -webkit-user-select: none;
    /* Safari */
    -khtml-user-select: none;
    /* Konqueror HTML */
    -moz-user-select: none;
    /* Old versions of Firefox */
    -ms-user-select: none;
    /* Internet Explorer/Edge */
    user-select: none;
    /* Non-prefixed version, currently
                                        supported by Chrome, Opera and Firefox */
}

.arrow-primary::marker {
    color: #9dc44d !important;
}

.arrow-primary::-webkit-details-marker {
    color: #9dc44d !important;
}

a {
    color: inherit;
}

a:hover {
    color: inherit;
}

.break-all {
    word-break: break-all;
}

@media(max-width: 575.98px) {
    details {
        margin-bottom: 0.8rem !important;
    }
}

.btn-circle {
    border-radius: 100%;
}


.data-table {
    font-size: 0.92rem;

    .table td,
    .table th {
        vertical-align: middle;
    }

    .table th {
        max-width: 300px;
        min-width: 100px;
    }

    .table td:first-child {
        max-width: 320px;
    }

    .table th.long-th {
        min-width: 180px;
    }

    .table th.x-long-th {
        min-width: 210px;
    }

}

.title-case::first-letter {
    text-transform: uppercase;
}